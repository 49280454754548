import { jwtDecode } from 'jwt-decode'
import { JWTPayload, base64url, EncryptJWT, jwtDecrypt } from 'jose'
import { decodeAtoB } from './decodeAtoB'

export function decodeJwt<T>(token: string): T | null {
    try {
        const decoded = jwtDecode<T>(token)
        return decoded
    } catch (err) {
        return null
    }
}

export async function signJWE(payload: JWTPayload) {
    const key = process.env.REACT_APP_JWE_SECRET
    const secret = base64url.decode(key)
    const alg = 'dir'
    const enc = 'A128CBC-HS256'

    return await new EncryptJWT({ ...payload, random: Date.now() })
        .setProtectedHeader({ alg, enc })
        .setIssuedAt()
        .setIssuer('ludusglobal:portal')
        .setAudience('ludusglobal:hub')
        .setExpirationTime('2h')
        .encrypt(secret)
}

export async function decodeJWE(token: string) {
    const key = process.env.REACT_APP_JWE_SECRET
    const secret = base64url.decode(key)
    const { payload } = await jwtDecrypt(token, secret, {
        issuer: 'ludusglobal:portal',
        audience: 'ludusglobal:hub',
    })

    return payload
}

export async function decodeValidationToken(token: string): Promise<{
    status: number
    data: object | string | boolean
    error: boolean | string
    code: string
}> {
    try {
        const data = await decodeJWE(token)
        return { status: 200, data: data, error: false, code: '' }
    } catch (error) {
        return { status: 400, data: false, error: error, code: '' }
    }
}

export async function decodeUserToken(token: string): Promise<{
    status: number
    data: object | string | boolean
    error: boolean | string
    code: string
}> {
    const tokenSplitted = token.split('.')
    const tokenCreationTime = parseInt(
        decodeAtoB(tokenSplitted[tokenSplitted.length - 1])
    )
    if (tokenCreationTime + 3600 * 24 * 1000 < Date.now()) {
        return { status: 401, data: false, error: 'Token has expired', code: '' }
    }
    const JWE = tokenSplitted.slice(0, -1).join('.')
    try {
        const user = await decodeJWE(JWE)
        return { status: 200, data: user, error: false, code: '' }
    } catch (error) {
        return { status: 400, data: false, error: error, code: '' }
    }
}

export async function decodeResetCodeToken(token: string): Promise<{
    status: number
    data: string | boolean | object
    error: boolean | string
    code: string
}> {
    try {
        const data = await decodeJWE(token)

        if (data.iat + 3600 * 24 * 1000 < Date.now() / 1000) {
            return {
                status: 401,
                data: false,
                error: 'Token has expired',
                code: 'Token has expired',
            }
        }
        return { status: 200, data, error: false, code: '' }
    } catch (error) {
        return {
            status: 400,
            data: false,
            error: 'Unable to read token',
            code: 'Token has expired',
        }
    }
}
