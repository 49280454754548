import { base64url, jwtDecrypt } from 'jose'

export async function decodeJWE(token: string) {
  const key = process.env.REACT_APP_JWE_SECRET

  if (!key) {
    throw new Error('REACT_APP_JWE_SECRET is not defined');
  }
  const secret = base64url.decode(key)
  const { payload } = await jwtDecrypt(token, secret, {
    issuer: 'ludusglobal:portal',
    audience: 'ludusglobal:hub',
  })

  return payload
}

export async function decodeUserToken(
  token: string
): Promise<{ status: number; data: object | string | boolean | any; error: boolean | string | unknown; code: string }> {
  try {
    const userData = await decodeJWE(token)
    return { status: 200, data: userData, error: false, code: '' }
  } catch (error) {
    return { status: 400, data: false, error: error, code: '' }
  }
}

