import { createContext, useState, useCallback, useContext, ReactNode, FC } from 'react'

export type UserData = {
    id: string
    email: string
    firstName: string
    lastName: string
    deploymentGroup: {
        name: string
        client: string
        portal: string
        usage_statistics: string
        api: string
    }
}

type Props = {
    children: ReactNode
}

export const AuthContext = createContext({
    user: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        clientCode: '',
        deploymentGroup: {
            name: '',
            client: '',
            portal: '',
            usage_statistics: '',
            api: '',
        }
    },
    addUser: (
        id: string,
        email: string,
        firstName: string,
        lastName: string,
        clientCode: string,
        deploymentGroup: UserData['deploymentGroup']
    ) => { },
    removeUser: () => { },
})

export const AuthContextProvider: FC<Props> = ({ children }) => {
    const [user, setUser] = useState({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        clientCode: '',
        deploymentGroup: {
            name: '',
            client: '',
            portal: '',
            usage_statistics: '',
            api: '',
        }
    })

    const addUser = (
        id: string,
        email: string,
        firstName: string,
        lastName: string,
        clientCode: string,
        deploymentGroup: UserData['deploymentGroup']
    ) => {
        setUser({
            id,
            email,
            firstName,
            lastName,
            clientCode,
            deploymentGroup
        })
    }

    const contextValue = {
        user: user,
        addUser: useCallback(
            (
                id: string,
                email: string,
                firstName: string,
                lastName: string,
                clientCode: string,
                deploymentGroup: UserData['deploymentGroup']
            ) => addUser(id, email, firstName, lastName, clientCode, deploymentGroup),
            []
        ),
        removeUser: useCallback(
            () =>
                setUser({
                    id: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    clientCode: '',
                    deploymentGroup: {
                        name: '',
                        client: '',
                        portal: '',
                        usage_statistics: '',
                        api: '',
                    }
                }),
            []
        ),
    }

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    )
}

export function useAuthContext() {
    const { user, addUser, removeUser } = useContext(AuthContext)
    return { user, addUser, removeUser }
}
