import { FC, useEffect, useState } from 'react'
import { decodeUserToken } from '../utils/decodeUserToken'
import { useNavigation } from '../context/navigationContext'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../context/authContext'
import { loginUserOnline } from '../services/userLogin'
import Loader from '../components/Loader'
import { decodeJwt } from '../utils/JWTE'
import { Typography } from '@mui/material'

const Login: FC = () => {
    const { addUser, user } = useAuthContext()
    const { navigateToRanking } = useNavigation()
    const { jwe } = useParams()
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const [accessDenied, setAccessDenied] = useState(false)
    const [connectionError, setConnectionError] = useState(false)

    useEffect(() => {
        const fetchUserData = async () => {
            if (jwe) {
                const result = await decodeUserToken(jwe)

                if (result.status === 200 && result.data.accessToken) {
                    window.sessionStorage.setItem('accessToken', result.data.accessToken)
                    setAccessToken(result.data.accessToken)
                } else {
                    const storedAccessToken = window.sessionStorage.getItem('accessToken')
                    if (storedAccessToken) {
                        setAccessToken(storedAccessToken)
                    } else {
                        setAccessDenied(true)
                    }
                }
            } else {
                const storedAccessToken = window.sessionStorage.getItem('accessToken')
                if (storedAccessToken) {
                    setAccessToken(storedAccessToken)
                } else {
                    setAccessDenied(true)
                }
            }
        }
        fetchUserData()
    }, [jwe])

    useEffect(() => {
        const autologin = async () => {
            if (!accessToken || accessToken === null) return
            const loginRequest = await loginUserOnline(accessToken)

            if (loginRequest?.noConnection) {
                setConnectionError(true)
                return
            }

            const userData: {
                id: string
                email: string
                firstName: string
                lastName: string
                clientCode: string
                deploymentGroup: {
                    name: string
                    client: string
                    portal: string
                    usage_statistics: string
                    api: string
                }
            } | null = decodeJwt(loginRequest.data?.userToken)

            if (userData) {
                const { id, email, firstName, lastName, clientCode, deploymentGroup } = userData
                return addUser(id, email, firstName, lastName, clientCode, deploymentGroup)
            }
        }
        autologin()
    }, [accessToken, addUser])

    useEffect(() => {

        if (user.clientCode !== '') navigateToRanking()

    }, [user])

    if (accessDenied) {
        return <Typography variant="h5" align="center" color="white">Access Denied</Typography>
    }

    if (connectionError) {
        return <Typography variant="h5" align="center" color="white">
            No internet connection, please connect to a network to continue
        </Typography>
    }

    return <>{accessToken === null && <Loader />}</>
}

export default Login
