import { ChangeEvent, useState, useEffect } from 'react'
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Card, TextField, Box,
    Typography
} from '@mui/material'
import ludusTheme from '../ludusTheme.d'
import { fetchUsersRanking } from '../services/fetchUsersRanking'
import { useAuthContext } from '../context/authContext'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import Loader from './Loader'

const USERS_PER_PAGE = 10

const Ranking = () => {
    const { user } = useAuthContext()
    const [rankingData, setRankingData] = useState<{ id: number, userName: string, scenary: string, company: string, points: number }[]>([])
    const [displayedData, setDisplayedData] = useState([])
    const [filter, setFilter] = useState('')
    const [accessDenied, setAccessDenied] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasInternet, setHasInternet] = useState(true)
    const [page, setPage] = useState(1)
    const { ref, inView } = useInView({ triggerOnce: false })
    const navigate = useNavigate()

    useEffect(() => {
        const handleOnline = () => setHasInternet(true)
        const handleOffline = () => setHasInternet(false)

        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    useEffect(() => {
        const accessToken = sessionStorage.getItem('accessToken')
        if (!accessToken) {
            setAccessDenied(true)
            return
        }
        if (!user || !user.clientCode) {
            navigate(`/login/${accessToken}`)
            return
        }
        loadRankingData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, navigate])

    const loadRankingData = async () => {
        setIsLoading(true)

        try {
            const response = await fetchUsersRanking(user.clientCode)

            if (!response) {
                setHasInternet(false)
                return
            }

            const { status, data, error } = response

            if (status !== 200) {
                console.error(error)
                setIsLoading(false)
                return
            }

            const formattedData = data.exercises.map((exercise: any) => ({
                id: exercise.ExerciseId,
                userName: exercise.UserEmail.split('@')[0],
                scenary: exercise.Name,
                company: exercise.UserClientCode,
                points: exercise.RawScore
            }))

            const uniqueUsersData = Array.from(
                formattedData.reduce((acc, current) => {
                    if (!acc.has(current.userName) || acc.get(current.userName).points < current.points) {
                        acc.set(current.userName, current)
                    }
                    return acc
                }, new Map<string, { id: number; userName: string; scenary: string; company: string; points: number }>())
                    .values()) as { id: number; userName: string; scenary: string; company: string; points: number }[]

            setRankingData(uniqueUsersData)
            setDisplayedData(uniqueUsersData.slice(0, USERS_PER_PAGE))
            setIsLoading(false)
        } catch (error) {
            console.error("Error loading data:", error)
            setHasInternet(false)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (inView && !isLoading && displayedData.length < rankingData.length) {
            setIsLoading(true)
            const nextPageData = rankingData.slice(displayedData.length, displayedData.length + USERS_PER_PAGE)
            setDisplayedData((prevData) => [...prevData, ...nextPageData])
            setPage((prevPage) => prevPage + 1)
            setIsLoading(false)
        }
    }, [inView, isLoading, page, rankingData, displayedData.length])

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }

    const filterData = displayedData.filter(row => {
        const matchesFilter = !filter || row.userName.toLowerCase().includes(filter.toLowerCase())
        return matchesFilter
    })

    if (accessDenied) {
        return <Typography variant="h5" align="center" color="white">Access Denied: Missing Token</Typography>
    }

    return (
        <TableContainer component={Card}
            sx={{
                boxShadow: '0px 0px 15px 4px rgba(255,255,255,0.1)',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '10px',
                marginTop: 3,
                maxHeight: '750px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { width: '6px', },
                '&::-webkit-scrollbar-track': { backgroundColor: 'transparent', borderRadius: '10px' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: '10px' },
                '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
                '&::-webkit-scrollbar-thumb:active': { backgroundColor: '#333' }
            }}>
            <Box sx={{ textAlign: 'center', backgroundColor: 'rgba(53, 227, 152, 0)', marginTop: 2 }}>
                <Typography variant="h4"
                    sx={{
                        fontSize: { xs: 24, sm: 30, md: 36, lg: 42 },
                        fontFamily: 'Barlow Condensed',
                        fontWeight: '500',
                        color: ludusTheme.palette.primary.main,
                    }}>
                    Ranking
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 18, md: 20, lg: 24 }, color: "white" }}>
                    Processing of waste
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: 0,
                paddingBottom: 2,
            }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={filter}
                    onChange={handleFilterChange}
                    size="small"
                    sx={{
                        width: '33%',
                        marginTop: 2,
                        marginLeft: 3,
                        fontFamily: 'Barlow Condensed',
                        '& .MuiInputBase-input': { color: 'white', fontWeight: 'bold' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'white' },
                            '&:hover fieldset': { borderColor: 'lightgray' },
                            '&.Mui-focused fieldset': { borderColor: 'white' }
                        },
                        '& .MuiInputLabel-root': {
                            color: ludusTheme.palette.primary.main,
                            fontWeight: "500",
                            fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
                        },
                    }}
                />
            </Box>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow sx={{
                        '& th': {
                            fontFamily: 'Barlow Condensed',
                            fontWeight: '500',
                            color: ludusTheme.palette.primary.main,
                            fontSize: { xs: 20, sm: 22, md: 24, lg: 26 }
                        },
                    }}>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">User</TableCell>
                        <TableCell align="center">Points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filterData.map((row, index) => (
                        <TableRow key={row.id}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0, },
                                '&:hover': { backgroundColor: 'rgba(53, 227, 152, 0.1)' },
                                '& td': {
                                    fontFamily: 'Barlow Condensed',
                                    fontWeight: '500',
                                    color: 'white',
                                    padding: { xs: '12px', sm: '10px', md: '8px', lg: '6px' },
                                    fontSize: { xs: 20, sm: 22, md: 24, lg: 26 }
                                },
                            }}>
                            <TableCell align='center' style={{ width: '33%' }}>{index + 1}</TableCell>
                            <TableCell align='center' style={{ width: '33%' }}>{row.userName}</TableCell>
                            <TableCell align="center" style={{ width: '33%' }}>{row.points}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isLoading ? (
                hasInternet ? <Loader /> : <Typography color="white" align="center" sx={{ padding: 2 }}>
                    No internet connection, please connect to a network to continue
                </Typography>
            ) : null}
            <div ref={ref} style={{ height: '10px' }}></div>
        </TableContainer>
    )
}

export default Ranking