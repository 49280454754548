import Container from '@mui/material/Container';
import { customAppStyles } from './ludusTheme.d'
import { Box } from '@mui/material'
import AppRoutes from './routes/router'

function App() {
  return (
    <Box component={'div'} sx={customAppStyles.contentBox}>
      <Container>
        <AppRoutes />
      </Container>
    </Box>
  );
}

export default App;
