import axios from 'axios';

export async function fetchUsersRanking(clientCode: string) {
  const apiUrl = process.env.REACT_APP_RANKING_API_URL as string;
  // const apiToken = process.env.REACT_APP_RANKING_API_TOKEN as string;
  const apiToken = sessionStorage.getItem('accessToken')

  if (!apiToken) {
    return { error: "AccessToken missing" };
  }

  return axios.post(apiUrl, { clientCode }, {
    headers: {
      Authorization: apiToken,
    },
  })
    .then(response => {
      return { status: response.data.status, data: response.data.data, error: null };
    })
    .catch(error => {
      console.error("Error fetching rankings:", error);
      return (error.response && error.response.data) ? error.response.data : null;
    });
}