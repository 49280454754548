import { Routes, Route, } from 'react-router-dom'
import Login from '../layouts/login'
import Ranking from '../components/Ranking'

const AppRoutes = () => {

    return (
        <Routes>
            <Route path="/login/:jwe" element={<Login />} />
            <Route path="/" element={<Ranking />} />
        </Routes>
    )
}

export default AppRoutes 
