import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const NavigationContext = createContext(null)

export const useNavigation = () => useContext(NavigationContext)

export const NavigationProvider = ({ children }) => {
    const navigate = useNavigate()

    const providerValue = useMemo(
        () => ({
            navigateToRanking: () => navigate('/'),
        }),
        [navigate]
    )

    return (
        <NavigationContext.Provider value={providerValue}>
            {children}
        </NavigationContext.Provider>
    )
}
