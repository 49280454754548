import axios from 'axios'

export type LoginUserResponse = {
    id: string
    firstName?: string
    lastName?: string
    email?: string
    accessToken?: string
    refreshToken?: string
    deploymentGroup: {
        name: string
        client: string
        portal: string
        usage_statistics: string
        api: string
    }
}

export const loginUserOnline = async (accessToken: string) => {
    try {
        const response = await axios.post<LoginUserResponse>(
            `${process.env.REACT_APP_AUTH_API_URL}/api/users/`,
            { accessToken },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
                    Accept: 'application/json',
                },
            }
        )
        return response.data
    } catch (error) {
        if (!error.response) {
            console.error("No internet connection, please connect to a network to continue")
            return { noConnection: true }
        }
        return error.response.data
    }
}
