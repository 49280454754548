import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import ludusTheme from './ludusTheme.d';
import App from './App';
import { AuthContextProvider } from './context/authContext';
import { NavigationProvider } from './context/navigationContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <NavigationProvider>
      <AuthContextProvider>
        <ThemeProvider theme={ludusTheme}>
          <App />
        </ThemeProvider>
      </AuthContextProvider>
    </NavigationProvider>
  </BrowserRouter>
);

